import * as React from 'react';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import whyAndWhen from '../../../images/educational-corner/blog18/kada-i-zasto-se-javlja-mucanje.png';
import differenceBetween from '../../../images/educational-corner/blog18/razlika-izmedu-mucanja-i-razvojne-netecnosti.png';

const LiquidAndNonLiquidSpeech = () => {
  return (
    <PageNew
      title="KOKOLINGO - Mucanje i razvojne netečnosti u govoru"
      description="Mucanje je razvojni poremećaj koji se najčešće javlja 
      između 2. i 5. godine i opisuje se kao ponavljanje glasova, slogova 
      i riječi te pojava umetanja, zastoja i produživanja glasova."
      headline="Tečan i netečan govor"
    >
      <Text as="p">
        Prije nego što krenemo s definiranjem mucanja, važno je upoznati se s
        dva bitna pojma, a to su tečnost i netečnost, odnosno{' '}
        <b>tečan i netečan govor</b>. Tečan govor podrazumijeva uravnoteženo
        održavanje mnogih elemenata govora: stanki, ritma, intonacije, naglaska,
        tempa.{' '}
        <b>
          Jedna je od karakteristika tečnoga govora i jednostavnost i lakoća
          njegova izvođenja
        </b>
        . Ipak, riječ je o vrlo složenom balansiranju spomenutih elemenata, pa
        ne čudi da mnogi s vremena na vrijeme izgube ovu ravnotežu i “spotaknu”
        se u govoru. Kada su ovakve epizode u govoru neuobičajeno učestale i
        jake, možemo posumnjati na mucanje.
      </Text>
      <Text as="p">
        Mnoge definicije mucanja opisuju govorna ponašanja osoba koje mucaju i
        kažu da je njihov govor netečan, odnosno da ima poremećen ritam i tempo.{' '}
        <b>
          U odnosu na osobu koja govori tečno, govor osobe koja muca djeluje kao
          da zahtijeva mentalni i fizički napor
        </b>
        .
      </Text>
      <Text as="h2">Što je mucanje i kada se obično prvi put javlja?</Text>
      <Text as="p">
        <b>
          Mucanje je razvojni poremećaj koji se najčešće javlja između 2. i 5.
          godine i opisuje se kao ponavljanje glasova, slogova i riječi te
          pojava umetanja, zastoja i produživanja glasova
        </b>
        . Zašto se javlja upravo u tom razdoblju? To je razdoblje intenzivnog
        razvoja jezika i govora, dijete počinje rabiti mnoge nove riječi,
        rječnik se brzo širi te se počinje koristiti složenim jezičnim
        strukturama. U tom razdoblju djetetov jezično-govorni sustav posebno je
        osjetljiv, a istodobno preopterećen novim komunikacijskim zahtjevima.
        Dakle, počeci mucanja poklapaju se s razvojnom fazom ubrzanog rasta
        ekspresivnih (govora) i receptivnih jezičnih sposobnosti (razumijevanja
        govora).
      </Text>
      <BlogImage
        imgSrc={whyAndWhen}
        imgAlt="Prikaz razloga zasto se javlja mucanje za odredene godine"
        imgTitle="Kada i zasto se javlja mucanje"
      />
      <Text as="h2">Uzroci mucanja i učestalost njegove pojave</Text>
      <Text as="p">
        Točni uzroci mucanja još nisu poznati, no postoje razne teorije koje se
        time bave. Neki istraživači kao uzrok mucanja izdvajaju obiteljsko
        okružje, neki kasnije sazrijevanje centra za govor u mozgu, neki
        psihogene uzroke, lošiju percepciju vlastitoga govora i još mnogo toga.
        Često se spominje i <i>Model zahtjeva i sposobnosti</i>, koji navodi
        neusklađenost u zahtjevima okoline s djetetovim jezičnim i kognitivnim
        sposobnostima. To znači da u jednom trenutku dijete, primjerice, može
        imati mnogo toga što bi htjelo reći (širok rječnik) i veliku želju to
        prenijeti drugima, ali centar za govor u mozgu još nije spreman to dobro
        realizirati.
      </Text>
      <Text as="p">
        Iz istraživanja koja se bave neurobiološkom podlogom mucanja može se
        zaključiti da se radi o prejakoj aktivnosti u području mozga za
        motoričku izvedbu govora, a preslaboj u području za obradu jezika.
      </Text>
      <Text as="p">
        Bez obzira na to koji je uzrok mucanja, gotovo je sigurno da je ono
        velikim dijelom genetski uvjetovano.
      </Text>
      <Text as="p">
        Između 30 i 60% osoba koje mucaju imaju obiteljsku povijest mucanja, dok
        manje od 10% osoba koje ne mucaju imaju zabilježeno mucanje u obitelji.
      </Text>
      <Text as="p">
        Mucanje se javlja 3-4 puta češće kod dječaka nego kod djevojčica. Prema
        novijim istraživanjima, procjenjuje se da u predškolskoj populaciji
        3,46% djece muca, a u školskoj populaciji 0,83%. Od njih 50% prevlada
        mucanje do puberteta.
      </Text>
      <Text as="h2">
        Kako razlikujemo mucanje od normalne, razvojne netečnosti?
      </Text>
      <Text as="p">
        Budući da se diferencijalna dijagnostika poremećaja tečnosti govora
        uglavnom temelji na analiziranju govora i govornih netečnosti, potrebno
        je naglasiti važnost razlikovanja mucajućih netečnosti i običnih
        netečnosti.
      </Text>
      <Text as="p">
        Netečnosti kao što su ponavljanja jednosložnih riječi, slogova i
        glasova, produžavanja glasova, napete pauze i blokade karakteriziraju
        govor osoba koje mucaju, ali se povremeno javljaju i u govoru tečnih
        osoba. Suprotno tome, ponavljanja višesložnih riječi, fraza,
        ispravljanja, nedovršeni iskazi, umetanja i oklijevanja netečnosti su
        koje se često javljaju u govoru svih govornika, ali i u govoru osoba
        koje mucaju.
      </Text>
      <Text as="p">
        <b>
          Mnoga djeca u dobi između 2. i 5. godine prolaze kroz razdoblje
          netečnog govora koji nije mucanje, nego normalna netečnost govora.
        </b>
      </Text>
      <Text as="p">
        Razvojne su netečnosti: ponavljanje cijelih višesložnih riječi ili
        fraza, ispravljanje rečenice, pauze ispunjene neinformativnim riječima
        („aaa“), pauze slične oklijevanju.
      </Text>
      <Text as="p">
        Mucajuće su netečnosti: ponavljanje jednosložnih riječi (pas pas pas),
        ponavljanje slogova i glasova (p-p-p-pas, pa-pa-pa-pas), čujna i nečujna
        produžavanja glasova (paaaaaas). Ponavljanja koja sadržavaju 3 ili više
        jedinica ponavljanja (npr. ba-ba-ba-balon) karakteristična su za
        mucanje, a iznimno su rijetka kod normalno tečne djece. Postojanje
        produžavanja duljih od 1 sekunde još je jedan snažan indikator mucanja.
      </Text>
      <Text as="p">
        Gotovo 50% djece koja mucaju iskazuju napete pokrete dijelova tijela,
        posebice glave, lica ili vrata. To nazivamo sekundarna ponašanja.
      </Text>
      <BlogImage
        imgSrc={differenceBetween}
        imgAlt="Prikaz razlika izmedu mucanja i razvoje netecnosti"
        imgTitle="Prikaz razlika izmedu mucanja i razvoje netecnosti"
      />
      <Text as="h2">Rizični faktori za pojavu mucanja</Text>
      <Text as="p">
        <ul>
          <li>prisutnost mucanja u užoj ili široj obitelji</li>
          <li>mucanje traje dulje od 6 mjeseci</li>
          <li>
            dječja zabrinutost u govoru i osjetljivost na pogreške u govoru
          </li>
          <li>negativne reakcije okoline</li>
          <li>
            popratne jezično-govorne teškoće (smanjen vokabular, sporiji priziv
            riječi, agramatične rečenice)
          </li>
        </ul>
      </Text>
      <Text as="h2">
        Kako pomoći malom djetetu koje muca (u predškolskoj dobi)?
      </Text>
      <Text as="p">
        <ul>
          <li>usporavati mu govor</li>
          <li>činiti veće pauze između iskaza</li>
          <li>smanjiti uzbuđenja u svakodnevici</li>
          <li>
            usmjeravanje više na igru, a manje na govorenje u interakcijama
            „jedan na jedan“
          </li>
          <li>češće upotrebljavati frazu „Pokaži mi!“, a ne „Reci mi!“</li>
          <li>
            otkriti stresore koji remete tečnost i pomoći roditeljima smanjiti
            stres
          </li>
          <li>
            smanjivanje zahtjeva (uzbuđenost, iznošenje kompliciranih mišljenja,
            brzi govor okoline, vremenski pritisak) i jačanje kapaciteta
            (motorički, socio-emocionalni, kognitivni)
          </li>
          <li>prihvaćanje netečnosti kao normalnog dijela učenja govora</li>
          <li>
            razgovor o mucanju razumljivim rječnikom – putovanje sporom cestom,
            prelazak preko mosta, sudar, govor kornjače
          </li>
        </ul>
      </Text>
      <Text as="p">
        Terapija se započinje kod vrlo male djece ako postoji:
        <ul>
          <li>napetost tijekom govora</li>
          <li>izbjegavanje govora, sram, frustracije</li>
          <li>zabrinutost okoline za djetetov govor</li>
          <li>pridruženi govorni ili jezični poremećaji</li>
          <li>drugi rizici djeteta i okoline</li>
        </ul>
      </Text>
      <Text as="h2">
        Opći savjeti za roditelje za prevladavanje razvojnih netečnosti
      </Text>
      <Text as="p">
        <ol>
          <li>
            Obratite pozornost na dijete kada vam se obraća. Pokažite da vas to
            zanima i nemojte ga požurivati. Pustite neka kaže sve do kraja i
            nemojte ga u tome nikako prekidati.
          </li>
          <li>
            Ne inzistirajte na govorenju ako dijete plače, ako je ozlijeđeno ili
            uzrujano. Umirite dijete, a onda s njim razgovarajte o onome što je
            uzrokovalo takvo ponašanje.
          </li>
          <li>
            Ne inzistirajte na tome da dijete prepričava pred drugima ako to ne
            želi.
          </li>
          <li>
            Kada se obraćate djetetu, govorite polako i razgovijetno. Biti
            pravilan govorni model mnogo je djelotvornije nego djetetu davati
            upute poput „Govori sporije.“, „Pokušaj ponovno.“, „Udahni.“
          </li>
          <li>
            Koristite se rečenicama koje rabi i vaše dijete, ne opterećujte ga s
            nepotrebno dugačkim i novim riječima – ima za to vremena. Neka vaše
            rečenice budu jednostavnije i kraće jer tako olakšavate djetetu i
            percepciju govora i govorenje.
          </li>
          <li>
            Pred djetetom ne raspravljajte zabrinuto o njegovu govoru. Dijete ne
            treba pomisliti kako s njegovim govorom nešto nije u redu.
          </li>
          <li>
            Upozorite ostale iz djetetove okoline (ukućane, odgojitelje,
            prijatelje) da se pridržavaju istih pravila.
          </li>
        </ol>
      </Text>
      <Text as="p">Ema Tadić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Andrijolić, A. i Leko Krhen, A. (2016). Diferencijalna dijagnostika
            poremećaja tečnosti govora.{' '}
            <i>Hrvatska revija za rehabilitacijska istraživanja</i>, 52(2),
            60-72.
          </li>
          <li>
            Dedić, M. i Leko Krhen, A. (2019). Utjecaj jezičnih sposobnosti na
            mucanje. <i>Logopedija 9</i>(1), 28-34.
          </li>
          <li>
            Jelčić Jakšić, S. (2014). Pomoć u usvajanju tehnika oblikovanja
            tečnog govora u terapiji mucanja: Pravila pet prstiju (PPP).{' '}
            <i>Logopedija 4</i>(1), 19-22.
          </li>
          <li>
            Pavičić Dokoza, K. i Hercigonja Salamoni, D. (2007).{' '}
            <i>Moje dijete muca – kako mu pomoći?</i>. Priručnik za roditelje.
            Poliklinika za rehabilitaciju slušanja i govora SUVAG: Zagreb.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default LiquidAndNonLiquidSpeech;
